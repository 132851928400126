import React from 'react'
import { CustomPendingEmail } from '../Components/MainComponents'


const PendingAccountVerify = () => {
  return (
    <CustomPendingEmail />
  )
}

export default PendingAccountVerify