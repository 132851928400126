import React from 'react'
import { FormRegister } from '../Components/MainComponents'


const RegisterPage = () => {
  return (
   <FormRegister />
  )
}

export default RegisterPage