export const FETCH_DATA_REQUEST_PRODUCT = "FETCH_DATA_REQUEST_PRODUCT";
export const FETCH_DATA_SUCCESS_PRODUCT = "FETCH_DATA_SUCCESS_PRODUCT";
export const FETCH_DATA_FAILURE_PRODUCT = "FETCH_DATA_FAILURE_PRODUCT";
export const FETCH_DATA_SUCCESS_PRODUCT_BY_ID = "FETCH_DATA_SUCCESS_PRODUCT_BY_ID";
export const FETCH_DATA_PRODUCT_RELATED = "FETCH_DATA_PRODUCT_RELATED";
export const FETCH_DATA_REQUEST_Related_PRODUCT = "FETCH_DATA_REQUEST_Related_PRODUCT";
export const FETCH_DATA_FAILURE_Related_PRODUCT = "FETCH_DATA_FAILURE_Related_PRODUCT";
export const FETCH_DATA_REQUEST_PRODUCT_BY_ID = "FETCH_DATA_REQUEST_PRODUCT_BY_ID";
export const FETCH_DATA_FAILURE_PRODUCT_BY_ID = "FETCH_DATA_FAILURE_PRODUCT_BY_ID";
export const FETCH_DATA_REQUEST_PRODUCT_FILTERED = "FETCH_DATA_REQUEST_PRODUCT_FILTERED";
export const FETCH_DATA_SUCCESS_PRODUCT_FILTERED = "FETCH_DATA_SUCCESS_PRODUCT_FILTERED";
export const FETCH_DATA_FAILURE_PRODUCT_FILTERED = "FETCH_DATA_FAILURE_PRODUCT_FILTERED";