import React from 'react'
import { LoginForm } from '../Components/MainComponents'



const LoginPage = () => {
  return (
    <>
    <LoginForm />
   
    </>
   
  )
}

export default LoginPage