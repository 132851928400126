import React from 'react'
import { CustomOrder } from '../Components/MainComponents'


const OrderPage = () => {
  return (
    <CustomOrder />
  )
}

export default OrderPage